@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 16px;
    /* https://github.com/system-fonts/modern-font-stacks#humanist */
    font-family:
      'IBM Plex Sans', Seravek, 'Gill Sans Nova', Ubuntu, Calibri, 'DejaVu Sans', source-sans-pro,
      sans-serif;
  }
  html.switching,
  html.switching * {
    transition-duration: 200ms;
    transition-timing-function: ease-in;
    transition-property:
      background-color, border-bottom-color, border-top-color, border-left-color,
      border-right-color, outline-color;
  }

  @font-face {
    font-family: fusion-pixel-ja;
    src: url('./fonts/fusion-pixel-10px-monospaced-ja.woff2');
  }
  :lang(ja) .font-pixel {
    font-family: fusion-pixel-ja, monospace;
  }
  @font-face {
    font-family: fusion-pixel-latin;
    src: url('./fonts/fusion-pixel-10px-monospaced-latin.woff2');
  }
  :lang(en) .font-pixel {
    font-family: fusion-pixel-latin, monospace;
  }
  @font-face {
    font-family: fusion-pixel-zh-hans;
    src: url('./fonts/fusion-pixel-10px-monospaced-zh_hans.woff2');
  }
  :lang(zh-CN) .font-pixel {
    font-family: fusion-pixel-zh-hans, monospace;
  }
  @font-face {
    font-family: fusion-pixel-zh-hant;
    src: url('./fonts/fusion-pixel-10px-monospaced-zh_hant.woff2');
  }
  :lang(zh-TW) .font-pixel {
    font-family: fusion-pixel-zh-hans, monospace;
  }
  .font-pixel {
    -webkit-font-smoothing: none;
    -moz-osx-font-smoothing: unset;
    font-smooth: never;
  }
  #__next {
    height: 100%;
  }
}

@layer components {
}

@layer utilities {
  .contain-strict {
    contain: strict;
  }
  .contain-content {
    contain: content;
  }
  .contain-size {
    contain: size;
  }
  .view-height {
    height: 100vh;
    height: 100dvh;
  }

  .select-bg-arrow {
    /* SVG from https://stackoverflow.com/a/67297063 */
    /* TODO: Change the color by themes */
    background-image: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23737373' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.75rem) center;
  }
}

@media all and (display-mode: standalone) {
  .standalone-bottom-padding {
    padding-bottom: 2.5rem;
  }
}
